import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../styles/global.scss'
import { DefaultSeo } from 'next-seo'
import { SEO } from '../constants/index'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'
import { LinkProvider } from '@fanduel/og-fe-library'
import Link from 'next/link'
import { PerimeterXScript } from '@/components/Misc/PerimeterXScript'
import { AmplitudeProvider } from '@/utils/hooks/useAmplitude'
import Script from 'next/script'
import GlobalStyles from 'GlobalStyles'
import { useRouter } from 'next/router'

declare global {
  interface Window {
    amplitude: unknown
  }
}

export default function MyApp({ Component, pageProps }: Readonly<{ Component: any; pageProps: any }>) {
  const NEXT_PUBLIC_VISIBLE_TO_SEARCH_ENGINES = process.env.NEXT_PUBLIC_VISIBLE_TO_SEARCH_ENGINES
  const titleTemplate = `%s | ${SEO.SITE_NAME}`

  const router = useRouter()
  const currentPath = router.pathname
  const currentUrl = router.asPath

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 100000,
          },
        },
      }),
  )
  return (
    <>
      <Script data-testid="google-tag-manager" id="google-tag-manager" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5DGV8G4M');`}
      </Script>
      <QueryClientProvider client={queryClient}>
        <AmplitudeProvider>
          <LinkProvider CustomLink={Link}>
            <PerimeterXScript />
            <main>
              <div style={{ zIndex: 0, display: 'none' }} data-testid="data-dog">
                <span> {`URL: ${currentUrl}`}</span>
              </div>
              <GlobalStyles>
                <DefaultSeo
                  titleTemplate={titleTemplate}
                  defaultTitle="Daily Fantasy Sports and Online U.S. Sportsbook | FanDuel"
                  dangerouslySetAllPagesToNoIndex={NEXT_PUBLIC_VISIBLE_TO_SEARCH_ENGINES?.toLowerCase() !== 'true'}
                  dangerouslySetAllPagesToNoFollow={NEXT_PUBLIC_VISIBLE_TO_SEARCH_ENGINES?.toLowerCase() !== 'true'}
                  openGraph={{
                    type: 'website',
                    locale: 'en_US',
                    url: SEO.FANDUEL_URL,
                    siteName: SEO.SITE_NAME,
                  }}
                  twitter={{
                    handle: SEO.TWITTERHANDLE,
                    site: SEO.SITE_NAME,
                    cardType: 'summary_large_image',
                  }}
                />
                <Component {...pageProps} />
              </GlobalStyles>
            </main>
          </LinkProvider>
        </AmplitudeProvider>
      </QueryClientProvider>
    </>
  )
}
